import React from "react";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import HomeMainView from '@/views/home/HomeMainView';
import MippumAppBar from "@/components/common/MippumAppBar";
import MippumDrawer from "@/components/common/MippumDrawer";

const drawerWidth = 240;

const Page = () => {
    const portrait = window.innerWidth < 1100;
    const [drawerOpen, setDrawerOpen] = React.useState(!portrait);

    return (<Box sx={{display:'flex'}}>
      <MippumAppBar title='미쁨에듀 온라인' drawerOpen={drawerOpen} toggleDrawer={()=>{setDrawerOpen(!drawerOpen)}} />
      <MippumDrawer drawerOpen={drawerOpen} />
      <MainView open={drawerOpen}>
        <AppBarGap />
        <HomeMainView drawerOpen={drawerOpen} />
      </MainView>
    </Box>)
}

const MainView = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0.5),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBarGap = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export { Page };
