import React from "react";
import { useNavigate } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ScienceIcon from '@mui/icons-material/Science';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';

import Divider from '@mui/material/Divider';

const drawerWidth = 240;

const MippumDrawer = ({drawerOpen: open}: MippumDrawerProps) => {

    const navigate = useNavigate()

    const [epubCollapseOpen, setEpubCollapseOpen] = React.useState(true);

    const [labCollapseOpen, setLabCollapseOpen] = React.useState(true);

    return (<Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <AppBarGap />
        <Divider />
        <List>
          <ListItem key='home' disablePadding>
            <ListItemButton onClick={() => { navigate("/"); }}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary='홈' />
            </ListItemButton>
          </ListItem>
          <Divider />

          <ListItem key='service' disablePadding onClick={() => { setLabCollapseOpen(!labCollapseOpen)}}>
            <ListItemButton>
              <ListItemIcon><NaturePeopleIcon /></ListItemIcon>
              <ListItemText primary='서비스' />
              {labCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Divider />
          <Collapse in={labCollapseOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 6 }} onClick={() => { navigate("/service/listening-trainer"); }}>
                <ListItemText primary="듣기 트레이너" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItem key='epub' disablePadding onClick={() => { setEpubCollapseOpen(!epubCollapseOpen)}}>
            <ListItemButton>
              <ListItemIcon><MenuBookIcon /></ListItemIcon>
              <ListItemText primary='전자책' />
              {labCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Divider />
          <Collapse in={labCollapseOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 6 }} onClick={() => { navigate("/epub/self-affirmation"); }}>
                <ListItemText primary="자기긍정확언" />
              </ListItemButton>
              <Divider />
              <ListItemButton sx={{ pl: 6 }} onClick={() => { navigate("/epub/preposition"); }}>
                  <ListItemText primary="1문1답 전치사" />
              </ListItemButton>

            </List>
          </Collapse>

          <ListItem key='lab' disablePadding onClick={() => { setLabCollapseOpen(!labCollapseOpen)}}>
            <ListItemButton>
              <ListItemIcon><ScienceIcon /></ListItemIcon>
              <ListItemText primary='실험실' />
              {labCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Divider />
          <Collapse in={labCollapseOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/*<ListItemButton sx={{ pl: 6 }} onClick={() => { navigate("/lab/rewind-and-repeat"); }}>*/}
              {/*  <ListItemText primary="거꾸로 암기" />*/}
              {/*</ListItemButton>*/}
              <ListItemButton sx={{ pl: 6 }} onClick={() => { navigate("/service/quiz"); }}>
                <ListItemText primary="퀴즈" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Drawer>)
}

interface MippumDrawerProps {
    drawerOpen: boolean;
}

const AppBarGap = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

export default MippumDrawer;

