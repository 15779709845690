
// type MPVoiceType = 'SpeechSynthesis' | 'AndroidTTS'; // todo: use

interface MPVoice {
  readonly default?: boolean;
  readonly lang: string;
  readonly name: string;
  readonly localService?: boolean;
  readonly voiceURI?: string;
  readonly originIndex: number;
  readonly identifier?: string,
  readonly quality?: string,
  readonly type: string; // todo: use MPVoiceType
}

const castToMPVoice = (voice: SpeechSynthesisVoice, index: number) => {
  return {
    originIndex: index,
    name: voice.name,
    lang: voice.lang.replace('_', '-'),
    default: voice.default,
    localService: voice.localService,
    voiceURI: voice.voiceURI,
    type: 'SpeechSynthesis',
  } as MPVoice;
}

// todo: 같은 lang 안에서 shuffle
// todo: 선호 voice 관리
// todo: voice 블랙리스트 관리
const sortVoices = (a: MPVoice, b: MPVoice, localLang = 'ko-KR', contentLang: string = 'en-US') => {
  if(a.lang !== b.lang) {
    if (a.lang === contentLang) return -1;
    if (b.lang === contentLang) return 1;

    if(a.lang === localLang) return -1;
    if(b.lang === localLang) return 1;

    if(a.lang === 'en-US') return -1;
    if(b.lang === 'en-US') return 1;
  }

  if(a.name !== b.name) {
    if (a.name === 'Aaron') return -1;
    if (b.name === 'Aaron') return 1;

    if (a.name === 'Samantha') return -1;
    if (b.name === 'Samantha') return 1;
  }

  if(a.default !== b.default) {
    if (a.default) return -1;
    if (b.default) return 1;
  }

  if(a.originIndex < b.originIndex) return -1;
  if(a.originIndex > b.originIndex) return 1;
  return 0;
}

export {sortVoices, castToMPVoice};
export type { MPVoice };


