import axios from 'axios';
import {getRandomInt} from "@/util/common/random";
import {getLocalStorage, setLocalStorage} from "@/util/common/web-storage";
import {getNowStr} from "@/util/common/datetime";

interface IContent {
  detail: string;
  lang: string;
}

interface IHistory {
  content: IContent,
  created: string,
}

interface IStoredContent {
  history: Array<IHistory>;
}

const LISTENING_TRAINER_SAMPLES = [{
  name: 'lincoln',
  nameKo: '링컨',
  url: process.env.PUBLIC_URL + '/content/service/listening-trainer/samples/lincoln.json',
  lang: 'en-US',
  short: 'Four score and seven years ago our fathers brought forth on this continent a new nation, conceived i'
}, {
  name: 'hamlet',
  nameKo: '햄릿',
  url: process.env.PUBLIC_URL + '/content/service/listening-trainer/samples/hamlet.json',
  lang: 'ko-KR',
  short:  '죽느냐 사느냐 그것이 문제로다.\n이 더러운 운명의 화살과 돌팔매를 견뎌내는 것이 더 고귀한가.\n아니면 고통의 바다를 힘으로 막아 싸워 이기고 함께 죽는 것',
}]

const loadSampleContent = async (url:string|null=null): Promise<IContent> => {

  try {
    if(url){
      const response = await axios.get(url);
      return response.data;
    } else {
      const sampleIndex = getRandomInt(LISTENING_TRAINER_SAMPLES.length-1);
      const response = await axios.get(LISTENING_TRAINER_SAMPLES[sampleIndex].url);
      return response.data;
    }
  } catch(err) {
    console.log("Error >>", err);
  }

  return {
    'detail': `Four score and seven years ago our fathers brought forth on this continent a new nation, conceived in liberty, and dedicated to the proposition that all men are created equal.\nNow we are engaged in a great civil war, testing whether that nation, or any nation, so conceived and so dedicated, can long endure. We are met on a great battle-field of that war. We have come to dedicate a portion of that field, as a final resting place for those who here gave their lives that that nation might live. It is altogether fitting and proper that we should do this.\n\nBut, in a larger sense, we can not dedicate, we can not consecrate, we can not hallow this ground. The brave men, living and dead, who struggled here, have consecrated it, far above our poor power to add or detract. The world will little note, nor long remember what we say here, but it can never forget what they did here. It is for us the living, rather, to be dedicated here to the unfinished work which they who fought here have thus far so nobly advanced. It is rather for us to be here dedicated to the great task remaining before us—that from these honored dead we take increased devotion to that cause for which they gave the last full measure of devotion—that we here highly resolve that these dead shall not have died in vain—that this nation, under God, shall have a new birth of freedom—and that government of the people, by the people, for the people, shall not perish from the earth.`,
    'lang': 'en-US'
  }
}

const splitContent = (contentLocal: string): Array<string> => {
  const sentences = contentLocal
    .replaceAll('.', '.▦')
    .replaceAll('?', '?▦')
    .replaceAll('!', '!▦')
    .replaceAll('—', '—▦')
    .split('▦')
    .filter((sentence) => sentence.length > 0)
  return sentences;
}

const loadListeningTrainerStorage = (): IStoredContent => {
  const stored = getLocalStorage('service.listening-trainer');
  if(!stored) return {history: []};
  if(!stored['history']) stored['history'] = [];
  return stored;
}

const addHistoryListeningTrainerStorage = (newContent: IContent) => {
  const stored = loadListeningTrainerStorage();
  const created = getNowStr();

  const newHistory = {
    content: newContent,
    created: created,
  };

  stored['history'].splice(0, 0, newHistory);
  stored['history'] = removeDuplicatedHistory(stored['history']);
  stored['history']  = stored['history'].slice(0, 20);

  setLocalStorage('service.listening-trainer', stored);
}

const removeDuplicatedHistory = (origins: Array<IHistory>) => {
  const results: Array<IHistory> = [];
  const seenContentDetails: Array<string> = [];
  for (let i = 0, length = origins.length; i < length; i++) {
    const origin = origins[i];
    if (!seenContentDetails.includes(origin.content.detail)) {
      seenContentDetails.push(origin.content.detail);
      results.push(origin);
    }
  }
  return results;
}

export { loadSampleContent, splitContent, loadListeningTrainerStorage, addHistoryListeningTrainerStorage, LISTENING_TRAINER_SAMPLES };
export type {IHistory};