import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Page as HomePage } from './pages/home'
import { Page as NotFoundPage } from './pages/home/not-found-error'

import { Page as SelfAffirmationPage } from './pages/epub/self-affirmation'
import { Page as PrepositionPage } from './pages/epub/preposition'

import { Page as ListeningTrainerPage } from './pages/service/listening-trainer'
import { Page as QuizPage } from './pages/service/quiz'
import { Page as RewindAndRepeatPage } from './pages/service/rewind-and-repeat'

import { Page as LabPage } from './pages/lab'
import { Page as IdiomQuizPage } from './pages/lab/idiom-quiz'
import { Page as ShortAnswerPage } from './pages/lab/quiz/short-answer'

const Router = () => {
    return (
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="/epub/self-affirmation" element={<SelfAffirmationPage />} />
            <Route path="/epub/preposition" element={<PrepositionPage />} />

            <Route path="/lab" element={<LabPage />} />
            <Route path="/service/listening-trainer" element={<ListeningTrainerPage />} />
            <Route path="/service/quiz" element={<QuizPage />} />
            <Route path="/lab/rewind-and-repeat" element={<RewindAndRepeatPage />} />

            <Route path="/lab/idiom-quiz" element={<IdiomQuizPage />} />
            <Route path="/lab/quiz/short-answer" element={<ShortAnswerPage />} />

            <Route path='*' element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
    );
  };

export default Router;
