import _ from 'lodash';

const isEmpty = (value: any) => {
  if(typeof value === 'number') return false;
  return _.isEmpty(value);
}

const isNotEmpty = (value: any) => {
  return !isEmpty(value);
}

const isNil = (value: any) => {
  return _.isNil(value);
}

const isNotNil = (value: any) => {
  return !isNil(value);
}

const shallowCopy = (value: any) => {
  return _.clone(value);
}

// const deepCopy = (value: any) => {
//   return _.cloneDeep(value);
// }

export { isEmpty, isNotEmpty, isNil, isNotNil, shallowCopy }
