import {Button, Dialog, DialogTitle, TextField} from "@mui/material";
import styled from "styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import React, {useEffect, useRef, useState} from "react";
import {isNotNil, isNil} from "@/util/common/handle-object";
import {splitContent} from "@/util/service/listening-trainer/content";
import wait from "waait";

type IProps = {
    isOpened: boolean,
    setIsOpened: Function,
    text: string,
    voiceIndex: number,
    repeatTotal: number,
}

const SpeakingDialog = (props: IProps) => {

    const contentSplit = splitContent(props.text);
    const [playContentSplitIndex, setPlayContentSplitIndex] = useState<number>(-1);
    const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
    const isSpeakingRef = useRef<boolean>(false);
    const [iteration, setIteration] = useState<number>(1);

    useEffect(() => {
        fetch().then(()=>{});
        return () => { // clear up code
            stop();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = async () => {
        if(isNil(window.speechSynthesis)) {
            props.setIsOpened(false);
            isSpeakingRef.current = false;
            return;
        }
        await startSpeakingLoop();
    }

    const stop = () => {
        setIsSpeaking(false);
        isSpeakingRef.current = false;
        setPlayContentSplitIndex(-1);
        setIteration(0);

        if(isNil(window.speechSynthesis)) {
            props.setIsOpened(false);
            return;
        }
        speechSynthesis.cancel();
    }

    const startSpeakingLoop = async () => {
      console.log('startSpeakingLoop');
      setIsSpeaking(true);
      setIteration(1);
      isSpeakingRef.current = true;

      const selectedVoice = speechSynthesis.getVoices()[props.voiceIndex];
      for (let i = 0; i < props.repeatTotal; i++) {
        setIteration(i + 1);
        for (let playIndex = 0; playIndex < contentSplit.length && isSpeakingRef.current; playIndex++) {
          // const sentence_element = document.getElementById('sentence-' + playIndex);
          // if (sentence_element) sentence_element.scrollIntoView();
          setPlayContentSplitIndex(playIndex);
          const msg =  new SpeechSynthesisUtterance();
          msg.voice = selectedVoice;
          msg.text = contentSplit[playIndex];
          console.log(msg.voice);
          speechSynthesis.speak(msg);

          while (isNotNil(window.speechSynthesis) && window.speechSynthesis.speaking) {
            await wait(300);
          }
        }
      }
      stop();
    }

    if(!props.isOpened) return (<></>);
    if(isNil(window.speechSynthesis)) return (<></>);

    return (<Dialog open={true}
                    fullScreen={true}
                    sx={{pr: 0, pl: 1}}>
        <TitleContainer>
            <ArrowBackIosNewIcon sx={{mr: 0}} onClick={() => {
                props.setIsOpened(false);
            }}/>
            <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
                듣기
            </DialogTitle>
        </TitleContainer>
        <div>
            {isSpeaking? <Button variant="outlined"
                                 className={'m-2'}
                                 sx={{marginLeft: '4px'}}
                                 onClick={() => {stop();}}><StopIcon /></Button>
                : <Button variant="outlined"
                          className={'m-2'}
                          sx={{marginLeft: '4px'}}
                          onClick={() => {startSpeakingLoop().then();}}><PlayArrowIcon /></Button>}

            <TextField label={'현재 횟수'}
                       value={iteration}
                       className={'w-20'}
                       size={'small'}
                       sx={{marginLeft: '20px'}} />
            <TextField label={'총 반복'}
                       value={props.repeatTotal}
                       className={'w-20'}
                       size={'small'}
                       sx={{marginLeft: '4px'}} />
        </div>
        <div className={'whitespace-pre-line p-2 border-2 border-black m-2'}>
            {contentSplit.map((sentence, index) => (
                <span id={'sentence-' + index}
                      className={'' + (index === playContentSplitIndex ? ' text-red-700' : '')}>{sentence}</span>
            ))}
        </div>
    </Dialog>)
}

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;


export default SpeakingDialog;

