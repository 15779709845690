import {getLocalStorage} from "@/util/common/web-storage";

const isAndroid = () => {
  const userAgent = navigator.userAgent.toLowerCase(); //userAgent 문자열 값 받아오기

  if (userAgent.indexOf("android") > -1) {
    return true;
  }
  return false;
  // if (userAgent.indexOf("android") > -1) {
  //   //안드로이드일 때 실행할 동작
  // } else if (
  //   userAgent.indexOf("iphone") > -1 ||
  //   userAgent.indexOf("ipad") > -1 ||
  //   userAgent.indexOf("ipod") > -1
  // ) {
  //   //IOS일 때 실행할 동작
  // } else {
  //   //아이폰, 안드로이드가 아닐 때 실행할 동작
  // }
}

interface IVoice {
  identifier: string,
  lang: string,
  name: string,
  quality: string,
  originIndex: number,
}

interface ITTS {
  voices: Array<IVoice>;
}

export default interface IDeviceStored {
  "platform-os": string;
  tts?: ITTS;
}

const getDeviceStored = (): IDeviceStored => {
  const deviceStored = getLocalStorage('device');
  // console.log(deviceStored);
  return deviceStored;
}

export { isAndroid, getDeviceStored };
