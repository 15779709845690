import React from "react";

// to do list
// - 외울 문구를 남기면, epub 파일 만들어 주는 서비스

const Page = () => {
    return (<>
        lab
    </>)

}

export { Page };
