import React from 'react';
import { useState, ReactNode } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
} from '@mui/material';
import styled from 'styled-components';

import CoverImage from '@/images/epub/preposition/cover.png';
import Example14QuestionImage from '@/images/epub/preposition/example-14-question.png';
import Example14AnswerCoverImage from '@/images/epub/preposition/example-14-answer.png';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import PlayBooksIcon from "@/images/epub/common/play_books_icon.svg";
import Yes24Icon from "@/images/epub/common/yes24_icon.svg";
import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";

const PrepositionView = () => {

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<ReactNode>(<DialogTitle />);
  const [dialogContent, setDialogContent] = useState<ReactNode>(<DialogContent />);

  return (<div>
    <h1 className={'mb-5'}><span className="mb-4 pr-3 text-3xl font-bold leading-normal">전치사 표현에 자신감을! 『미쁨1문1답 영문 전치사』 전자책</span>
    </h1>
    <p className='flex justify-center'>
      <img className="border-2 w-1/3" src={CoverImage} alt={'CoverImage'} />
      <img className="border-2 w-1/3" src={Example14QuestionImage} alt={'Example14QuestionImage'} />
      <img className="border-2 w-1/3" src={Example14AnswerCoverImage} alt={'Example14AnswerCoverImage'} />
    </p>

    <p className='flex justify-center'>
      <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
      <Link href="https://www.yes24.com/Product/Goods/126670604"><span>예스24 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>

      <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
      <Link href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>

    <p className="mt-4 text-lg indent-2">
      읽기나 듣기와는 달리, 쓰기나 말할 때에
      전치사 표현은 많이 고민하게 됩니다.
    </p>
    <p className="mt-4 text-lg indent-2">
      위치, 움직임, 시간 관련 전치사 표현은 규칙적으로 생각하고 적용할 수 있지만
      많은 전치사 표현이 관용적인 숙어가 많아 외워야 하는 것이 많습니다.
      특히 말할 때 바로 표현이 생각나려면 반복 학습이 필요합니다.
    </p>
    <p className="mt-4 text-lg indent-2">
      반복학습으로 전치사 표현에 자신감을 가져가세요!
    </p>
    <p className="mt-4 text-lg indent-2">
      『미쁨1문1답 영문 전치사』 전자책은 <b>TTS</b>(Text-To-Speech)를 활용하여 쉽게 들으며
      반복학습을 할 수 있도록 하였습니다.
    </p>
    <h1 className={'mt-5'}><span className="mb-4 pr-3 text-3xl font-bold leading-normal">TTS 시연</span></h1>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/I2Bd2m2pe6k?si=D8m7h97xEIWQy9Me"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <br/>
    </p>
    <p className='flex justify-center'>예스24 eBook 앱에서 『미쁨1문1답 영문 전치사』 책 TTS 시연 영상</p>

    <p className="mt-4 text-lg indent-2">
      TTS를 활용하면 쉽게 『미쁨1문1답 영문 전치사』 전자책을 쉽게 들으며 반복학습을 할 수 있도록 하였습니다. 다만, 플랫폼 별로 TTS 방식이 다르니 이점 유의 바랍니다.
    </p>

    <p className="mt-4 text-lg indent-2">
      플랫폼 별 TTS 시연 보기
      <ul>
        <li>- <Link component="button" underline="hover" onClick={() => {
          setDialogTitle(getYes24TTSDialogTitle());
          setDialogContent(getYes24TTSDialogContent());
          setDialogOpen(true);
        }}>{'예스24 TTS 시연'} </Link> <span className={'text-sm'}>(안드로이드와 아이폰 TTS 기능 차이 없음. 한영 모두 읽음)</span></li>
        <li>- <Link component="button" underline="hover" onClick={() => {
          setDialogTitle(getAndroidPlayBookTTSDialogTitle());
          setDialogContent(getAndroidPlayBookTTSDialogContent());
          setDialogOpen(true);
        }}>{'안드로이드 Play북 TTS 시연'} </Link> <span className={'text-sm'}>(안드로이드의 글자읽어주기 기능 활용. 영문만 읽음)</span></li>
        <li>- <Link component="button" underline="hover" onClick={() => {
          setDialogTitle(getIphonePlayBookTTSDialogTitle());
          setDialogContent(getIphonePlayBookTTSDialogContent());
          setDialogOpen(true);
        }}>{'아이폰 Play북 TTS 시연'} </Link> <span className={'text-sm'}>(아이폰의 화면읽어주기 기능 활용. 페이지별 화면넘기기로 보기 불가)</span></li>
      </ul>
    </p>

    <p className="mt-4 text-lg indent-2">
      아래 다음 카페 게시글로 더 많은 내용 확인할 수 있습니다.
    </p>

    <List>
      <ListItem key='home' disablePadding className={'border-t-4 border-b-2'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getBookReleasedDialogTitle());
          setDialogContent(getBookReleasedDialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『미쁨1문1답 영문 전치사』 출시
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              『미쁨1문1답 영문 전치사』 전자책이 출시되었습니다. 읽기나 듣기와는 달리, 쓰기나 말할 때에...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key='yes24' disablePadding className={'border-t-2 border-b-2'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getYes24DialogTitle());
          setDialogContent(getYes24DialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『미쁨1문1답 영문 전치사』 예스24 eBook 도움말
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              이 게시글은 『미쁨1문1답 영문 전치사』 전자책을 예스24 eBook에서 구매해서 사용하는 이용자를 위한 도움말입니다...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key='android-playbook' disablePadding className={'border-t-2 border-b-2'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getAndroidPlayBookDialogTitle());
          setDialogContent(getAndroidPlayBookDialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『미쁨1문1답 영문 전치사』 안드로이드 Play북 도움말
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              이 게시글은 『미쁨1문1답 영문 전치사』 전자책을 안드로이드 Play 북에서 구매해서 사용하는 이용자를 위한 도움말입니다...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
      <ListItem key='iphone-playbook' disablePadding className={'border-t-2 border-b-4'}>
        <ListItemButton onClick={() => {
          setDialogTitle(getIphonePlayBookDialogTitle());
          setDialogContent(getIphonePlayBookDialogContent());
          setDialogOpen(true);
        }}>
          <ListItemText primary={<span>
                            『미쁨1문1답 영문 전치사』 아이폰 Play북 도움말
                            <span className={'text-gray-400 text-xs'}> — 워드프레스 관련 게시글 보기</span>
                          </span>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{display: 'inline'}}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              이 게시글은 『미쁨1문1답 영문 전치사』 전자책을 아이폰 Play 북에서 구매해서 사용하는 이용자를 위한 도움말입니다...
                            </Typography>
                          </React.Fragment>
                        }
          />
        </ListItemButton>
      </ListItem>
    </List>

    <Dialog open={dialogOpen}
            fullScreen={true}
            sx={{pr: 0, pl: 1}}
            PaperProps={{style: {minHeight: '90%', maxHeight: '90%'}}}>
      <TitleContainer>
        <ArrowBackIosNewIcon sx={{mr: 0}} onClick={() => setDialogOpen(false)}/>
        {dialogTitle}
      </TitleContainer>
      {dialogContent}
    </Dialog>

  </div>);

}

const getBookReleasedDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
  워드프레스 관련 게시글 보기
  <IconButton className={"MyCustomButton"}>
    <OpenInNew sx={{fontSize: 15}}
               onClick={() => window.open("https://mippum.wordpress.com/2024/07/08/epub-preposition-release", '_blank')}/>
    <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/08/epub-preposition-release'}>https://mippum.wordpress.com/2024/07/08/epub-preposition-release</a>
  </IconButton>
</DialogTitle>
};

const getBookReleasedDialogContent = () => {
  return <DialogContent>
  <iframe src="https://mippum.wordpress.com/2024/07/08/epub-preposition-release"
          className={'border-2'}
          width={'100%'}
          height={'100%'}
          title="W3Schools Free Online Web Tutorials">
  </iframe>
</DialogContent> };


const getAndroidPlayBookDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/13/epub-preposition-android-playbook", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/13/epub-preposition-android-playbook'}>https://mippum.wordpress.com/2024/07/13/epub-preposition-android-playbook</a>
    </IconButton>
  </DialogTitle>
};

const getAndroidPlayBookDialogContent = () => {
  return <DialogContent>
    <iframe src="https://mippum.wordpress.com/2024/07/13/epub-preposition-android-playbook"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };

const getIphonePlayBookDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/13/epub-preposition-ios-playbook", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/13/epub-preposition-ios-playbook'}>https://mippum.wordpress.com/2024/07/13/epub-preposition-ios-playbook</a>
    </IconButton>
  </DialogTitle>
};

const getIphonePlayBookDialogContent = () => {
  return <DialogContent>
    <iframe src="https://mippum.wordpress.com/2024/07/13/epub-preposition-ios-playbook"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };

const getYes24DialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold', mr: 10}}>
    워드프레스 관련 게시글 보기
    <IconButton className={"MyCustomButton"}>
      <OpenInNew sx={{fontSize: 15}}
                 onClick={() => window.open("https://mippum.wordpress.com/2024/07/11/epub-preposition-yes24", '_blank')}/>
      <a className={'text-gray-400 text-xs'} target={'_blank'} rel="noreferrer" href={'https://mippum.wordpress.com/2024/07/11/epub-preposition-yes24'}>https://mippum.wordpress.com/2024/07/11/epub-preposition-yes24</a>
    </IconButton>
  </DialogTitle>
};

const getYes24DialogContent = () => {
  return <DialogContent>
    <iframe src="https://mippum.wordpress.com/2024/07/11/epub-preposition-yes24"
            className={'border-2'}
            width={'100%'}
            height={'100%'}
            title="W3Schools Free Online Web Tutorials">
    </iframe>
  </DialogContent> };


const getYes24TTSDialogTitle = () => { return <DialogTitle sx={{fontWeight: 'bold'}}>
  예스24 TTS 시연
</DialogTitle> };

const getYes24TTSDialogContent = () => { return <DialogContent>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/I2Bd2m2pe6k?si=b9ufnLcoFob96VjB"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </p>
    <p className='flex justify-center'>
      <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
      <Link href="https://www.yes24.com/Product/Goods/126670604"><span>예스24 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>
    <p className={'mt-4 text-lg indent-2'}>
      예스24 eBook 앱은 자체 TTS 를 사용하기 때문에, 안드로이드와 아이폰의 차이가 없습니다.
    </p>
  </DialogContent>
  }
;

const getAndroidPlayBookTTSDialogTitle = () => { return <DialogTitle sx={{fontWeight: 'bold'}}>
  안드로이드 Play북 TTS 시연
</DialogTitle> };

const getAndroidPlayBookTTSDialogContent = () => { return <DialogContent>
  <p className='flex justify-center'>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/LJgJ8W5C6wA?si=ifa3LjX8wBWXlf_I"
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
  </p>
  <p className='flex justify-center'>
    <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
    <Link href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북 구매</span><OpenInNew
      sx={{fontSize: 15}}/></Link>
  </p>
  <p className={'mt-4 text-lg indent-2'}>
    안드로이드 Play북의 "소리내어 읽기" 기능은, 안드로이드의 "글자읽어주기" 기능을 활용합니다.
  </p>
</DialogContent>
};

const getIphonePlayBookTTSDialogTitle = () => {
  return <DialogTitle sx={{fontWeight: 'bold'}}>
    아이폰 Play북 TTS 시연
  </DialogTitle>
};

const getIphonePlayBookTTSDialogContent = () => {
  return <DialogContent>
    <p className='flex justify-center'>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/9SevlSLb8po?si=wo2aT-OIexIQg0bH"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    </p>
    <p className='flex justify-center'>
      <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
      <Link href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북 구매</span><OpenInNew
        sx={{fontSize: 15}}/></Link>
    </p>
    <p className={'mt-4 text-lg indent-2'}>
      아이폰 Play북은 "소리내어 읽기" 기능이 없습니다. 한 화면에 있는 글자만 읽어주는, 아이폰의 "화면읽어주기" 기능을 활용합니다. 이를 위한 부록 페이지를 활용합니다.
    </p>
  </DialogContent>
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default PrepositionView;
