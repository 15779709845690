import React, { useEffect, useState } from "react";

import {
    Select,
    MenuItem,
    Button,
    TextField,
    InputLabel,
    FormControl,
} from '@mui/material';

import wait from "waait";

const RewindAndRepeatView = () => {

    const [voices, setVoices] = useState<Array<IVoice>>([]);
    const [selectedVoiceIndex, setSelectedVoiceIndex] = useState<number>(0);
    const [content, setContent] = useState<string>(defaultContent);
    const [contentSplit, setContentSplit] = useState<Array<string>>([]);

    // setContentSplit(defaultContent.split('.'));

    useEffect(() => {
        async function fetch() {
            await loadVoices();
            const sentences = defaultContent.split('.');
            setContentSplit(sentences.filter((sentence) => sentence.length > 0).map((sentence) => sentence + '.'));
        }
        fetch();
    }, []);

    const loadVoices = async () => {

        if(speechSynthesis.getVoices().length === 0) await wait(1000);

        const voices: Array<IVoice> = speechSynthesis.getVoices().map(
            (voice, index) => {
                return {index: index, name:voice.name, lang: voice.lang, default: voice.default};
            }).sort((a, b) => {
            if(a.name !== b.name) {
                if (a.name === 'Aaron') return -1;
                if (b.name === 'Aaron') return 1;

                if (a.name === 'Samantha') return -1;
                if (b.name === 'Samantha') return 1;
            }
            if(a.default !== b.default) {
                if (a.default) return -1;
                if (b.default) return 1;
            }
            if(a.lang !== b.lang) {
                if (a.lang === 'ko-KR') return -1; // todo: default 의 lang 을 앞으로
                if (a.lang === 'ko_KR') return -1;
                if (b.lang === 'ko-KR') return 1;
                if (b.lang === 'ko_KR') return 1;

                if(a.lang === 'en-US') return -1;
                if(a.lang === 'en_US') return -1;
                if(b.lang === 'en-US') return 1;
                if(b.lang === 'en_US') return 1;

            }
            if(a.index < b.index) return -1;
            if(a.index > b.index) return 1;
            return 0;
        });
        setVoices(voices);
        if(voices.length > 0) setSelectedVoiceIndex(voices[0].index);
    };

    const speak = () => {
        const msg = new SpeechSynthesisUtterance(content);
        msg.voice = speechSynthesis.getVoices()[selectedVoiceIndex];
        speechSynthesis.speak(msg);
        // console.log(content);
    }

    return (<div>
        <div>
            <Button variant="contained" onClick={() => {
                speak();
            }}>Speak</Button>
            <Button variant="contained" color={'error'} onClick={() => {
                speechSynthesis.cancel();
            }}>Stop</Button>
            <FormControl sx={{m: 1, minWidth: 300}}>
                <InputLabel id="voice-select-label">Voice</InputLabel>
                <Select
                    labelId={'voice-select-label'}
                    value={selectedVoiceIndex}
                    label="Voice"
                    autoWidth
                    onChange={(event) => {
                        setSelectedVoiceIndex(Number(event.target.value))
                    }}
                >
                    {voices.map((voice) => (
                        <MenuItem key={voice.index} value={voice.index}>{voice.name} [{voice.lang}]</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
        <div>
            <TextField label="Content"
                       multiline
                       rows={20}
                       fullWidth
                       onChange={(event) => {
                           setContent(event.target.value);
                       }}
                       value={content}/>
            <div style={{ whiteSpace: "pre-line" }}>
                {contentSplit.map((sentence, index) => (
                    <span>{sentence}</span>
                ))}
            </div>
        </div>
    </div>)

};

interface IVoice {
    index: number;
    name: string;
    lang: string;
    default: boolean;
}

const defaultContent = `죽느냐 사느냐 그것이 문제로다.
이 더러운 운명의 화살과 돌팔매를 견뎌내는 것이 더 고귀한가.
아니면 고통의 바다를 힘으로 막아 싸워 이기고 함께 죽는 것이 옳은 일인가.
죽는 건 그저 잠자는 것.
잠이 들면 마음의 상심도, 육신이 물려받는 수천 가지 타고난 고통도 끝나는 법.
잠을 잔다는 것은 꿈을 꾼다는 말이겠지. 아, 그것이 어려운 노릇이로다. 
우리가 이 육신의 쇠사슬을 끌고 죽음의 수면 속을 들어갈 때 우리는 어떤 꿈을 꾸게 될 것인가? 
일찍이 아무도 갔다가 돌아온 일이 없는 미지의 저승에는 어떤 무서움이 있는지 모르기에, 이 고통을 참고 또 견디는 것이 아닐까? 
만일 죽어서 무서운 게 없다면, 누가 이 세상에서 무거운 짐을 끌고 갈 것인가? 
이 시간의 사나운 채찍을 견디며, 권력자의 잘못과 세도가의 멸시, 경멸적인 사랑의 고통스러움과 끝없는 소송, 관리들의 오만 그리고 인내의 가치가 하찮은 자들에게 받는 멸시를, 이 모든 것은 어떻게 참고 지내겠는가?
단검 한 자루면 조용하고 편안해지는데. 누가 무거운 짐을 지고 피곤한 인생에 신음하며 땀을 흘리겠는가?
아름다운 오필리어 여신이여, 그대의 기도로써 제발 나를 구하여 다오.`;

export default RewindAndRepeatView;
