import React from "react";

import MippumAppBar from "@/components/common/MippumAppBar";
import MippumDrawer from "@/components/common/MippumDrawer";
import { Box } from "@mui/material";

const Page = () => {
    const portrait = window.innerWidth < 1100;
    const [drawerOpen, setDrawerOpen] = React.useState(!portrait);

    return (<Box sx={{display:'flex'}}>
        <MippumAppBar title='퀴즈 단답형' drawerOpen={drawerOpen} toggleDrawer={()=>{setDrawerOpen(!drawerOpen)}} />
        <MippumDrawer drawerOpen={drawerOpen} />
        <>공사중</>
    </Box>)
}

export {Page};
