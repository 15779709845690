import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';

import Divider from "@mui/material/Divider";
import EpubPrepositionCoverImage from "@/images/epub/preposition/cover.png";
import Example14AnswerCoverImage from "@/images/epub/preposition/example-14-answer.png";
import Yes24Icon from "@/images/epub/common/yes24_icon.svg";
import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";
import PlayBooksIcon from "@/images/epub/common/play_books_icon.svg";
import {useNavigate} from "react-router-dom";
import EpubSelfAffirmationCoverImage from '@/images/epub/self-affirmation/cover.png';
import Example13Image from "@/images/epub/self-affirmation/example-13.png";

import HomeListeningTrainerPlayImage from "@/images/home/listening-trainer-play.png";

const HomeMainView = ({drawerOpen}: HomeMainViewProps) => {
  const navigate = useNavigate();
  const isVerticalImgAlign = window.innerWidth < 600;

  return (<>
    <div className="mt-1 text-right">
      <span className="pr-1 text-green-600">믿을 수 있는 학습 시스템을 지향합니다.</span>
    </div>

    <h2 className="mt-2 text-3xl font-bold leading-normal">서비스</h2>
    <List>
      <Divider />
      <ListItem key={'listening-trainer'} disablePadding>
        <ListItemButton onClick={() => {navigate("/service/listening-trainer");}}>
          <Box className="w-1/3">
            <img className="border-gray-500 border-2" src={HomeListeningTrainerPlayImage} alt={'HomeListeningTrainerPlayImage'}/>
          </Box>
          <ListItemText primary={<span className={'flex justify-center font-bold'}>듣기 트레이너</span>} secondary={
            <React.Fragment>
              <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="body2"
                  color="text.primary"
              >
                <p className={'mt-2 mb-2 flex justify-center text-center'}>
                  특정 문구를 외우기 위해 <br/>
                  혹은 명상을 위해
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  자주 반복 듣기가 필요해요.
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  듣기 트레이너로 반복 듣기를 해보세요.
                </p>
              </Typography>
            </React.Fragment>
          } />
        </ListItemButton>
      </ListItem>
      <Divider />
    </List>

    <h2 className="mt-4 text-3xl font-bold leading-normal">전자책</h2>
    <List>
      <Divider />
      <ListItem key={'epub-preposition'} disablePadding>
        <ListItemButton onClick={() => { navigate("/epub/preposition"); }}>
          { isVerticalImgAlign ? <Box sx={{padding:0}} className="w-1/4">
            <img className="w-1/1" src={EpubPrepositionCoverImage} alt={'CoverImage'}/>
            <img className="border-2 w-1/1" src={Example14AnswerCoverImage} alt={'Example14AnswerCoverImage'}/>
          </Box> : <>
            <Box className="w-1/5">
              <img src={EpubPrepositionCoverImage} alt={'CoverImage'}/>
            </Box>
            <Box className="w-1/5">
              <img className="border-2" src={Example14AnswerCoverImage} alt={'Example14AnswerCoverImage'}/>
            </Box>
          </> }

          <ListItemText className={'pl-3'} primary={<span className={'flex justify-center font-bold'}>미쁨 1문1답 영문 전치사</span>} secondary={
            <React.Fragment>
              <Typography
                sx={{display: 'inline'}}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <p className={'mt-2 mb-2 flex justify-center text-center'}>
                  전치사 표현에 자신감을!<br/>
                  회화할 때 가장 헷갈리는 전치사.<br/>
                  이젠 자신있게 말할 수 있어요.
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  I saw her <span className={'underline text-gray-400'}>_(at/in/on)_</span> his car.
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  밑줄에 들어갈 전치사가 헷갈린다면 확인해보세요!
                </p>
                <p className='flex justify-center text-center'>
                  <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
                  <Link href="https://www.yes24.com/Product/Goods/126670604"><span>예스24</span><OpenInNew
                    sx={{fontSize: 15}}/></Link>

                  <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
                  <Link
                    href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북</span><OpenInNew
                    sx={{fontSize: 15}}/></Link>
                </p>
              </Typography>
            </React.Fragment>
          }/>
        </ListItemButton>
      </ListItem>
      <Divider/>
      <ListItem key={'epub-self-affirmation'} disablePadding>
        <ListItemButton onClick={() => {navigate("/epub/self-affirmation");}}>
          { isVerticalImgAlign ? <Box sx={{padding:0}} className="w-1/4">
            <img className="w-1/1" src={EpubSelfAffirmationCoverImage} alt={'CoverImage'}/>
            <img className="border-2 w-1/1" src={Example13Image} alt={'Example14AnswerCoverImage'}/>
          </Box> : <>
            <Box className="w-1/5">
              <img src={EpubSelfAffirmationCoverImage} alt={'CoverImage'}/>
            </Box>
            <Box className="w-1/5">
              <img className="border-2" src={Example13Image} alt={'Example14AnswerCoverImage'}/>
            </Box>
          </> }
          <ListItemText className={'pl-3'}
                        primary={<span className={'flex justify-center font-bold'}>영어로 하는 미쁨 자기긍정확언</span>} secondary={
            <React.Fragment>
              <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="body2"
                  color="text.primary"
              >
                <p className={'mt-2 flex justify-center text-center'}>
                  I am cool.
                </p>
                <p className={'flex justify-center text-center text-gray-400'}>
                  나는 멋져.
                </p>
                <p className={'flex justify-center text-center'}>
                  I firmly believe in my self and my abilities.
                </p>
                <p className={'mb-2 flex justify-center text-center text-gray-400'}>
                  나는 나 자신과 내 능력을 확고하게 믿는다.
                </p>
                <p className={'flex justify-center text-center'}>
                  내 삶을 풍요롭게 하는 자기긍정확언.
                </p>
                <p className={'mb-2 flex justify-center text-center'}>
                  영어 음성으로 자주 들어보세요!
                </p>
                <p className='flex justify-center text-center'>
                  <img className='w-4' src={Yes24Icon} alt={'Yes24Icon'}/>
                  <Link href="https://www.yes24.com/Product/Goods/126670604"><span>예스24</span><OpenInNew
                      sx={{fontSize: 15}}/></Link>

                  <img className='w-4 ml-4' src={PlayBooksIcon} alt={'PlayBooksIcon'}/>
                  <Link
                      href="https://play.google.com/store/books/details?id=O-UGEQAAQBAJ"><span>Play북</span><OpenInNew
                      sx={{fontSize: 15}}/></Link>
                </p>
              </Typography>
            </React.Fragment>
          }/>
        </ListItemButton>
      </ListItem>
      <Divider/>
    </List>
  </>);
};

interface HomeMainViewProps {
  drawerOpen: boolean;
}

export default HomeMainView;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f7f7f7',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
}));
