
const getNowStr = () => {
  const now = new Date();
  return formatDateStr(now);
}

const formatDateStr = (date: Date) => {
  return `${date.getFullYear()}-${padLeft(date.getMonth() + 1)}-${padLeft(date.getDate())} ${padLeft(date.getHours())}:${padLeft(date.getMinutes())}:${padLeft(date.getSeconds())}`;
}

const padLeft = (nr:number, len = 2, padChr = `0`) =>
  `${nr < 0 ? `-` : ``}${`${Math.abs(nr)}`.padStart(len, padChr)}`;

export { getNowStr, formatDateStr };
